/* @flow */

import React from 'react';
import { css } from 'linaria';

import Link from './Link';

const links = {
  Contact: 'mailto:ferrannp@gmail.com',
  Twitter: 'https://twitter.com/ferrannp',
  Github: 'https://github.com/ferrannp',
  Linkedin: 'https://linkedin.com/in/ferrannp',
};

const TopNav = () => {
  return (
    <nav className={nav}>
      {Object.keys(links).map((key) => (
        <Link key={key} className={link} href={links[key]}>
          {key}
        </Link>
      ))}
    </nav>
  );
};

const nav = css`
  text-align: right;
  padding: 20px 20px 0;
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

const link = css`
  padding: 12px 16px;
  font-size: 14px;
  @media only screen and (max-width: 600px) {
    padding: 4px 8px;
  }
`;

export default TopNav;
