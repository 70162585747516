/* @flow */

const projects = [
  {
    imagePath: require('../../assets/fithero.png'),
    title: 'FitHero',
    links: [
      {
        href:
          'https://apps.apple.com/app/fithero-gym-workout-tracker/id1465707550',
        title: 'App Store',
      },
      {
        href: 'https://play.google.com/store/apps/details?id=com.fnp.fithero',
        title: 'Play Store',
      },
      { href: 'https://fithero.io', title: 'Website' },
    ],
  },
  {
    imagePath: require('../../assets/audioprofiles.png'),
    title: 'Audio Profiles',
    links: [
      {
        href:
          'https://play.google.com/store/apps/details?id=com.fnp.audioprofiles',
        title: 'Play Store',
      },
    ],
  },
];

export default projects;
