/* @flow */

import React from 'react';
import { css } from 'linaria';

import colors from '../utils/colors';
import Paragraph from './Paragraph';

type Props = {
  className?: string,
};

const Footer = ({ className }: Props) => {
  return (
    <footer className={className ? `${footer} ${className}` : footer}>
      <Paragraph
        className={p}
      >{`© Copyright ${new Date().getFullYear()} Ferran Negre Pizarro`}</Paragraph>
    </footer>
  );
};

const footer = css`
  box-shadow: inset 0 1px 0 ${colors.divider};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const p = css`
  color: ${colors.secondaryText};
  font-size: 14px;
  margin-bottom: 20px;
`;

export default Footer;
