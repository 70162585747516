/* @flow */

import React from 'react';

import Paragraph from '../components/Paragraph';
import Link from '../components/Link';

const OpenSource = () => {
  return (
    <Paragraph>
      Both my work and personal time involve open-source. I created several
      libraries such as{' '}
      <Link href="https://github.com/ferrannp/react-native-sync-adapter/">
        react-native-sync-adapter
      </Link>{' '}
      and{' '}
      <Link href="https://github.com/ferrannp/material-preferences">
        material-preferences
      </Link>
      . I also like to create useful examples such as{' '}
      <Link href="https://github.com/ferrannp/react-native-testing-example">
        react-native-testing-example
      </Link>
      . Furthermore I continuously contribute and/or maintain libraries I often
      use like{' '}
      <Link href="https://github.com/react-native-community/react-native-viewpager">
        react-native-viewpager
      </Link>
      ,{' '}
      <Link href="https://github.com/callstack/react-native-paper">
        react-native-paper
      </Link>
      , <Link href="https://github.com/facebook/jest"> jest</Link> and
      <Link href="https://github.com/facebook/react-native"> react-native</Link>
      .
    </Paragraph>
  );
};

export default OpenSource;
