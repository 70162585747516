/* @flow */

import * as React from 'react';
import { css } from 'linaria';

type Props = {
  className?: string,
  children: React.Node,
};
const Paragraph = ({ className, children }: Props) => {
  return <p className={className ? `${p} ${className}` : p}>{children}</p>;
};

const p = css`
  line-height: 1.7;
  a:hover {
    text-decoration: underline;
  }
`;

export default Paragraph;
