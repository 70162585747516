/* @flow */

import React, { Fragment } from 'react';
import { css } from 'linaria';

import Paragraph from '../components/Paragraph';
import Link from '../components/Link';

import projects from '../data/projects';

const Projects = () => {
  return (
    <ul className={appList}>
      {projects.map((p) => (
        <li className={appItem} key={p.title}>
          <img
            alt={p.title}
            className={appIcon}
            src={p.imagePath}
            height="64"
            width="64"
          />
          <div className={appInfo}>
            <h4 className={appTitle}>{p.title}</h4>
            <Paragraph className={linksContainer}>
              {p.links.map((l, i) => (
                <Fragment key={l.href}>
                  <Link href={l.href}>{l.title}</Link>
                  {i === p.links.length - 1 ? '' : ' - '}
                </Fragment>
              ))}
            </Paragraph>
          </div>
        </li>
      ))}
    </ul>
  );
};

const appList = css`
  margin-top: 20px;
`;

const appItem = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

const appIcon = css`
  border-radius: 14px;
`;

const appInfo = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
`;

const appTitle = css`
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 8px;
`;

const linksContainer = css`
  font-size: 15px;
  margin: 0;
`;

export default Projects;
