/* @flow */

import React from 'react';

import Paragraph from '../components/Paragraph';
import Link from '../components/Link';

const Blog = () => {
  return (
    <Paragraph>
      I usually write on <Link href="https://medium.com/@ferrannp">Medium</Link>
      . Some articles I am proud of are listed in the official documentation of
      Jest. They are about unit testing React Native apps:{' '}
      <Link href="https://medium.com/p/68ba19b1b9fe">part 1</Link> and{' '}
      <Link href="https://medium.com/p/8559f6f8050b">part 2</Link>. I also wrote
      about the process of creating a native module{' '}
      <Link href="https://medium.com/p/517ddf851bf4">
        React Native: The Android Sync Adapter
      </Link>{' '}
      as well as{' '}
      <Link href="https://medium.com/p/7e85f01fc099">
        Android Drawer & StatusBar done right for React Native
      </Link>{' '}
      and{' '}
      <Link href="https://medium.com/p/c883056a8f5c">
        React Native: Handling language changes on Android the right way
      </Link>
      . Finally, I also wrote the official announcement for the{' '}
      <Link href="https://medium.com/p/40eebd88be98">
        React Native Paper 1.0
      </Link>{' '}
      release.
    </Paragraph>
  );
};

export default Blog;
