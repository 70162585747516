/* @flow */

import React from 'react';
import { css } from 'linaria';

import SEO from '../components/SEO';
import TopNav from '../components/TopNav';
import Header from '../components/Header';
import SectionItem from '../components/SectionItem';
import Paragraph from '../components/Paragraph';
import OpenSource from '../sections/OpenSource';
import Blog from '../sections/Blog';
import Footer from '../components/Footer';
import Projects from '../sections/Projects';
import Appearances from '../sections/Appearances';

const Home = () => (
  <main>
    <SEO title="Ferran Negre Pizarro" />
    <TopNav />
    <Header className={page} />
    <section className={`${page} ${section}`}>
      <ul>
        <SectionItem title="About me">
          <Paragraph>
            I have this particular hobby of creating applications that are
            useful, beautiful and fast. Backed up with my skills in React &
            React Native, I also unleash the power of native (Android/iOS) when
            necessary. You are right,{' '}
            <span className={lineThrough}>my job</span> the gym is my hobby.
          </Paragraph>
        </SectionItem>
        <SectionItem title="Personal projects">
          <Paragraph>
            In my free time, I write mobile applications and publish them.
          </Paragraph>
          <Projects />
        </SectionItem>
        <SectionItem title="Speaking & Appearances">
          <Appearances />
        </SectionItem>
        <SectionItem title="Open-source">
          <OpenSource />
        </SectionItem>
        <SectionItem title="Blog">
          <Blog />
        </SectionItem>
      </ul>
    </section>
    <Footer className={page} />
  </main>
);

const page = css`
  max-width: 600px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const section = css`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const lineThrough = css`
  text-decoration: line-through;
`;

export default Home;
