/* @flow */

import * as React from 'react';
import { css } from 'linaria';

type Props = {
  children: React.Node,
  title: string,
};

const SectionItem = ({ title, children }: Props) => {
  return (
    <li className={li}>
      <h3>{title}</h3>
      {children}
    </li>
  );
};

const li = css`
  margin-bottom: 40px;
`;

export default SectionItem;
