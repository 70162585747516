/* @flow */

import React from 'react';
import { css } from 'linaria';

import appearances, { icons } from '../data/appearances';
import Paragraph from '../components/Paragraph';
import colors from '../utils/colors';
import Link from '../components/Link';

const Appearances = () => {
  // $FlowFixMe
  return appearances.map((a) => {
    return (
      <React.Fragment key={a.year}>
        <div className={lineHeaderContainer}>
          <h4 className={h4}>{a.year}</h4>
          <div className={line} />
        </div>
        <ul>
          {a.items.map((i) => {
            const { icon, alt } = icons(i.type);
            return (
              <li
                className={talkListItem}
                key={`${a.year}-${i.title}-${i.location}`}
              >
                <img
                  alt={alt}
                  className={iconType}
                  src={icon}
                  height="24"
                  width="24"
                />
                <Paragraph className={p}>
                  {i.link ? (
                    <Link className={link} href={i.link}>
                      {i.title}
                    </Link>
                  ) : (
                    <span className={link}>{i.title}</span>
                  )}
                  {i.location}
                </Paragraph>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  });
};

const lineHeaderContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
`;

const h4 = css`
  margin: 0 1em 0 0;
  width: 2em;
  font-weight: 500;
`;

const line = css`
  width: 100%;
  height: 1px;
  background: rgb(187, 187, 187);
`;

const talkListItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5em;
`;

const iconType = css`
  flex-shrink: 0;
  fill: red;
  height: 36px;
  width: 36px;
`;

const p = css`
  margin: 0 1.5em 0;
`;

const link = css`
  display: block;
  color: ${colors.text};
  font-weight: 500;
`;

export default Appearances;
