/* @flow */

const colors = {
  text: '#121212',
  divider: '#eee',
  link: '#42a5f5',
  secondaryText: '#919191',
};

export default colors;
