/* @flow */

import React from 'react';
import { css } from 'linaria';
import colors from '../utils/colors';

const avatarImage = require('../../assets/ferran.jpg');

type Props = {
  className?: string,
};

const Header = ({ className }: Props) => {
  return (
    <header className={className ? `${header} ${className}` : header}>
      <img
        alt="Ferran"
        className={avatar}
        src={avatarImage}
        height="150"
        width="150"
      />
      <h1 className={h1}>Ferran Negre Pizarro</h1>
      <h2 className={h2}>Software Engineer (React Native)</h2>
    </header>
  );
};

const header = css`
  text-align: center;
  padding-top: 20px;
  @media only screen and (max-width: 600px) {
    padding-top: 40px;
  }
`;

const avatar = css`
  border-radius: 50%;
  margin-bottom: 20px;
`;

const h1 = css`
  font-size: 2em;
  letter-spacing: -0.5px;
  margin: 0;
  @media only screen and (max-width: 600px) {
    font-size: 1.6em;
  }
`;

const h2 = css`
  font-size: 1.5em;
  color: ${colors.secondaryText};
  font-weight: 200;
  margin: 10px 0 0;
  @media only screen and (max-width: 600px) {
    font-size: 1.3em;
  }
`;

export default Header;
