/* @flow */

const questions = require('../../assets/questions.svg');
const video = require('../../assets/video.svg');
const workshop = require('../../assets/workshop.svg');

type AppearanceType = 'questions' | 'video' | 'workshop';

type AppearanceItemType = {
  type: AppearanceType,
  title: string,
  location: string,
  link?: string,
};

type AppearancesListType = {
  year: number,
  items: Array<AppearanceItemType>,
};

const appearances: Array<AppearancesListType> = [
  {
    year: 2019,
    items: [
      {
        type: 'questions',
        title: 'Q&A Panel',
        link: 'https://www.youtube.com/watch?v=W6AVWUPTy48',
        location: 'React Native EU (Wrocław, Poland)',
      },
      {
        type: 'video',
        title: 'React Native - Case study: FitHero',
        link: 'https://www.youtube.com/watch?v=8Vu6VlsNXMA',
        location: 'Chain React (Portland, USA)',
      },
    ],
  },
  {
    year: 2018,
    items: [
      {
        type: 'video',
        title: 'The dark side of Background tasks in React Native',
        link: 'https://www.youtube.com/watch?v=8MBQr9cSdHc',
        location: 'React Alicante 2018 (Alicante, Spain)',
      },
      {
        type: 'video',
        title: 'Paper 2.0: Material Design',
        link: 'https://www.youtube.com/watch?v=0CxUzjWkRms',
        location: 'React Native EU (Wrocław, Poland)',
      },
      {
        type: 'workshop',
        title: 'React Native Workshop',
        link: 'https://react.amsterdam/2018',
        location: 'React Amsterdam (Amsterdam, Netherlands)',
      },
      {
        type: 'video',
        title: 'The dark side of Background tasks in React Native',
        link: 'https://www.youtube.com/watch?v=ZlkEimenw9E',
        location: 'React Native Camp (Kiev, Ukraine)',
      },
    ],
  },
  {
    year: 2017,
    items: [
      {
        type: 'video',
        title: 'React Native - Case Study: Showman',
        link: 'https://www.youtube.com/watch?v=xBHhBwdugHE',
        location: 'React Alicante 2017 (Alicante, Spain)',
      },
      {
        type: 'workshop',
        title: 'Navigation in React Native Workshop',
        link: 'https://react-native.eu/2017/#workshops',
        location: 'React Native EU (Wrocław, Poland)',
      },
      {
        type: 'workshop',
        title: 'Getting started with React Native Workshop',
        link: 'https://react.amsterdam/2017/workshops.html',
        location: 'React Amsterdam (Amsterdam, Netherlands)',
      },
      {
        type: 'video',
        title: 'Testing React & Redux applications with the *new* Jest',
        link: 'https://www.youtube.com/watch?v=Impu1T23v8c',
        location: 'FrontEnd Con (Warsaw)',
      },
    ],
  },
];

// $FlowFixMe
export const icons = (type: AppearanceType): { icon: string, alt: string } => {
  switch (type) {
    case 'questions':
      return {
        icon: questions,
        alt: 'Panelist or Q&A item',
      };
    case 'video':
      return {
        icon: video,
        alt: 'Talk with video item',
      };
    case 'workshop':
      return {
        icon: workshop,
        alt: 'Workshop or teaching item',
      };
    default: {
      new Error(`${type} is not supported.`);
    }
  }
};

export default appearances;
