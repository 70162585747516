/* @flow */

import * as React from 'react';
import { css } from 'linaria';

import colors from '../utils/colors';

type Props = {
  children: string,
  className?: string,
  href: string,
};

const Link = ({ href, className, children, ...rest }: Props) => {
  return (
    <a
      className={className ? `${link} ${className}` : link}
      href={href}
      {...rest}
    >
      {children}
    </a>
  );
};

const link = css`
  text-decoration: none;
  color: ${colors.link};
  &:hover {
    text-decoration: underline;
  }
`;

export default Link;
